import { atom, useAtom } from "jotai";

const stepAtom = atom(0);

const stepEditAtom = atom(0);

const stepViewAtom = atom(0);

const PageCountAtom = atom(0);

const PageSearch = atom('')

const isLoginAtom = atom(false);

const employeeTableDataAtom = atom([]);

const PayrollTableDataAtom = atom([]);

const ReportsTableDataAtom = atom([]);

const adminAtom = atom({
  email: "",
  profile_picture: "",
  name: "",
});

const employeeDataAtom = atom({
  first_name: "",
  last_name: "",
  employee_id: "",
  profile_picture: "",
  email: "",
  mobile: "",
  gender: "",
  dob: new Date(),
  age: "",
  marital_status: "",
  street_address: "",
  city: "",
  country: "",
  state: "",
  zip: "",
  job_info: {
    join_date: new Date(),
    job_title: "",
    experience: "",
    employment_type: "",
    location_type: "",
    relieving_date: null,
  },
  payment_detail: {
    payment_method: "",
    hra: "",
    basic: "",
  },
});

const employeeEditDataAtom = atom({
  first_name: "",
  last_name: "",
  employee_id: "",
  profile_picture: "",
  email: "",
  mobile: "",
  gender: "",
  dob: "",
  age: "",
  marital_status: "",
  street_address: "",
  city: "",
  country: "",
  state: "",
  zip: "",
  status: true,
  job_info: {
    join_date: new Date(),
    job_title: "",
    experience: "",
    employment_type: "",
    location_type: "",
    relieving_date: null,
  },
  payment_detail: {
    payment_method: "",
    hra: "",
    basic: "",
  },
});

const payslipDataAtom = atom({
  pay_slip_month: "",
  company_logo: "",
  company_name: "",
  street: "",
  pin_code: "",
  country: "",
  employee_name: "",
  employee_id: "",
  pay_period: "",
  paid_days: "",
  paid_date: null,
  loss_pay_days: "",
  pay_summary: {},
  earnings: {
    basic: "",
    hra: "",
  },
  deductions: {
    incomeTax: "",
    providentFund: "",
  },

  gross_earnings: "",
  total_deduction: "",
  total_net_payable: "",
  amount_in_words: "",
});

const payslipEditDataAtom = atom({
  pay_slip_month: "",
  company_logo: "",
  company_name: "",
  street: "",
  pin_code: "",
  country: "",
  employee_name: "",
  employee_id: "",
  pay_period: "",
  paid_days: "",
  paid_date: null,
  loss_pay_days: "",
  pay_summary: {},
  earnings: {},
  deductions: {
    incomeTax: "",
    providentFund: "",
  },
  gross_earnings: "",
  total_deduction: "",
  total_net_payable: "",
  amount_in_words: "",
});

export {
  stepAtom,
  PageCountAtom,
  employeeDataAtom,
  stepEditAtom,
  stepViewAtom,
  isLoginAtom,
  employeeEditDataAtom,
  payslipDataAtom,
  payslipEditDataAtom,
  adminAtom,
  employeeTableDataAtom,
  PayrollTableDataAtom,
  ReportsTableDataAtom,
  PageSearch
};

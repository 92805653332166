import ApiService from "./ApiService";

// Common function to handle API requests with error handling
async function apiRequest(url, method, data) {
  try {
    return await ApiService.fetchData({
      url,
      method,
      data,
    });
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling at a higher level if needed
  }
}

export async function apiSignIn(data) {
  return apiRequest("/login", "post", data);
}

export async function apiSignUp(data) {
  return apiRequest("/register", "post", data);
}

export async function apiSignOut() {
  return apiRequest("/logout", "post");
}

export async function apiRefresh() {
  return apiRequest("/refresh-token", "post");
}

import axios from 'axios';
import appConfig from 'configs/app.config';
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant';
import { PERSIST_STORE_NAME } from 'constants/app.constant';
import deepParseJson from 'utils/deepParseJson';
import store from '../store';
import {
	onSignInSuccess,
	onSignOutSuccess,
	setToken,
} from '../store/auth/sessionSlice';
import { baseURL } from 'baseurl';
import { apiRefresh } from './AuthService';
import { useSelector, useDispatch } from 'react-redux';
const unauthorizedCode = [401];
// const baseURL = 'http://localhost:4000/api/v1';

const BaseService = axios.create({
	timeout: 60000,
	baseURL: `${baseURL}`,
	withCredentials: true,
});

export const BaseServicePublic = axios.create({
	timeout: 60000,
	baseURL: `${baseURL}`,
});
let isFetchingRefreshToken = false;
let callTime = 0;
BaseService.interceptors.request.use(
	(config) => {
		console.log('Request Object Start ', config.url, callTime);
		const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
		const persistData = deepParseJson(rawPersistData);

		const accessToken = persistData.auth.session.token;
		// const accessToken = localStorage.getItem("token")
		// console.log(
		// 	accessToken,
		// 	'accessToken',
		// 	config.headers[REQUEST_HEADER_AUTH_KEY]
		// );

		if (isFetchingRefreshToken && !config.url.includes('/refresh-token')) {
			config.isAppReject = true;
			console.log('call all url while calling refresh token', config.url);
			return Promise.reject(config);
		}
		if (
			isFetchingRefreshToken &&
			config.url.includes('/refresh-token') &&
			callTime > 1
		) {
			config.isAppReject = true;
			console.log('calling refresh token twice', config.url);
			return Promise.reject(config);
		}
		if (accessToken && !config.headers[REQUEST_HEADER_AUTH_KEY]) {
			/**
			 * Before sending the request, we check if the Authorization token is already present in the request headers.
			 * Since the dispatch function is asynchronous and may take some time to update the token in the state,
			 * we need to ensure that the latest token is used in the request headers.
			 *
			 * If the Authorization token is not present in the headers, we add it to the request headers using the accessToken obtained from local storage.
			 * The backend server is responsible for handling the headers and validating the token.
			 */

			config.headers[REQUEST_HEADER_AUTH_KEY] = `${accessToken}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
console.log('First Initialization');
BaseService.interceptors.response.use(
	(response) => response,
	async (error) => {
		const prevRequest = error?.config;
		console.log('Response With Error start');
		/**
		 * when our server sent 401 code that means token expired & 403 means invalid, and prev request is failed call, 
		 * so it's checking again same called failed or not using "prevRequest.sent" if prev request again we will logout user
		 */
		if (error?.response?.status === 401 && !prevRequest?.sent) {
			console.log('Fail with', 401);
			prevRequest.sent = true;
			// const dispatch = useDispatch()
			/**
			 * when first time failed request due to token expired we will call,
			 * we use callTime here cause suppose in our app 3 api call concurrently running with same token,
			 * and those 3 token 3 times give us 401 and /refresh-token route call will 3 time,
			 * after first time refresh-token  token will be invalid and after first  call others call detect as reuse token,
			 * that means our user will log out, we don't want it , 
			 * The "callTime" variable is used to track concurrent API calls with the same expired token.
			 *  if it's more than 1 others will will be reject
			 */
			try {
				isFetchingRefreshToken = true;
				callTime++;
				console.log(
					isFetchingRefreshToken,
					'start calling refresh token'
				);
				const response = await apiRefresh();
				callTime = 0;
				isFetchingRefreshToken = false;
				prevRequest.headers['Authorization'] =
					response.data.accessToken;
				console.log(prevRequest, 'previous request');
				localStorage.setItem('token', response.data.accessToken);

				store.dispatch(setToken(response.data.accessToken));
				return BaseService(prevRequest);
			} catch (error) {
				console.log(
					error,
					'error while call refresh token or call baseService'
				);
				if (!error?.isAppReject) {
					store.dispatch(onSignOutSuccess());
					return Promise.reject(error);
				}
			}
		} else {
			console.log('Fail with any code', 403);
			console.log(error, 'error object');
			if (isFetchingRefreshToken) {
				console.log('Log from inside isFetching');
				return Promise.reject(error);
			}
			store.dispatch(onSignOutSuccess());
		}
		return Promise.reject(error);
	}
);

export default BaseService;

import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { Button } from "components/ui";

const DateFilterMenu = ({ filterOpen, close, dateRanges, getData, setDateTypes }) => {
  const [dateType, setDateType] = useState("");
  const [dateRange, setDateRange] = useState(null);

  const handleClick = async () => {
    if (dateType.length > 0) {
      if (dateType === "DateRange") {
        setDateTypes(dateType);
        dateRanges(dateRange);
      } else {
        setDateTypes(dateType);
      }
      getData(true);
      close(false);
    }
  };

  const dateTypeOptions = [
    "Today", "Yesterday", "Last Week", "This Week", 
    "Last Month", "This Month", "This Year", "Last Year", "All"
  ];

  return (
    <div
      className={` ${
        filterOpen ? "visible" : "hidden"
      } absolute p-4 right-0 z-20 mt-2 w-[400px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="mt-1 relative">
        <h3>Time Period</h3>
        <Flatpickr
          onClick={() => setDateType("DateRange")}
          value={dateRange}
          id="range-picker"
          className="w-full font-normal mt-2 border border-gray-300 rounded-md py-2 px-2 !bg-white active:!bg-white focus:!bg-white"
          onChange={selectedDates => {
            if (selectedDates.length === 2) {
              const [startDate, endDate] = selectedDates;
              setDateRange([
                startDate.toISOString(),
                endDate.toISOString()
              ]);
            }
          }}
          options={{
            mode: "range"
          }}
        />
      </div>
      <div className="days">
        {dateTypeOptions.map(option => (
          <div
            key={option}
            onClick={() => {
              // setDateType(prev => (prev === option ? "" : option));
              setDateType(option);
              setDateRange(null);
            }}
            className={dateType === option && "active"}
          >
            {option}
          </div>
        ))}
      </div>
      <div className="flex gap-4 mt-4">
        <Button className="flex-1" type="button" onClick={() => setDateType("")}>
          Clear
        </Button>
        <Button className="flex-1" type="button" variant="solid" onClick={handleClick}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default DateFilterMenu;

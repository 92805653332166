import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import useQuery from "./useQuery";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  
  const { token, signedIn } = useSelector((state) => state.auth.session);

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        const { accessToken, profile_picture, roles, email, name } = resp.data.data;
        dispatch(onSignInSuccess(accessToken));
        dispatch(setUser({
          profile_picture: profile_picture || "",
          email: email || "",
          name: name || "",
          roles: roles || "",
        }));
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl || appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.toString();
      return {
        status: "failed",
        message: errorMessage,
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        dispatch(setUser(resp.data.user || {
          avatar: "",
          userName: "Anonymous",
          authority: ["USER"],
          email: "",
        }));
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl || appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.toString();
      return {
        status: "failed",
        message: errorMessage,
      };
    }
  };

  const signOut = async () => {
    try {
      await apiSignOut();
      handleSignOut();
    } catch (error) {
      console.error(error);
      handleSignOut();
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;

import React, { memo, useMemo, lazy, Suspense, useEffect, useState } from 'react'
import { Loading } from 'components/shared'
import { useSelector } from 'react-redux'
import {

	LAYOUT_TYPE_MODERN,

	LAYOUT_TYPE_BLANK
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import { useAtom } from 'jotai'
import { isLoginAtom } from 'atoms'
// import useLocale from 'utils/hooks/useLocale'

const layouts = {
	[LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
	[LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {

	const layoutType = useSelector((state) => state.theme.layout.type)

	const { authenticated } = useAuth()


	// useDirection()

	// useLocale()
	const storedIsLogin = localStorage.getItem('isLogin');
	console.log("hiiiii-layout", storedIsLogin)

	const AppLayout = useMemo(() => {
		if (authenticated) {
			return layouts[layoutType]
		}
		return lazy(() => import('./AuthLayout'))
		// }, [layoutType])
	}, [layoutType, authenticated])


	return (
		<Suspense
			fallback={
				<div className="flex flex-auto flex-col h-[100vh]">
					<Loading loading={true} />
				</div>
			}
		>
			<AppLayout />
		</Suspense>
	)
}

export default memo(Layout)


const appConfig = {
       

    apiPrefix: '/api/v1',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    enableMock:false
}
// console.log(window.location.pathname=="/sign-in")
// console.log(window.location)

export default appConfig